export const sampleScheduleData = [
  {
    'Event Name': '',
    'Venue Name': '',
    'Address (Must pre-exist in the system)': '',
    'Meeting Spot': '',
    'Contact Name & Phone No': '',
    'Event Details': '',
    'Parking (Format: Yes or No)': '',
    'PO# (Optional)': '',
    'Catering Company(Optional)': '',
    'Date (Format: MM-DD-YYYY)': '',
    'Position Name': '',
    'Qty (# of shifts for that position and timeslot)': '',
    'Start Time (Format: Military Time)': '',
    'End Time (Format: Military Time)': '',
    'Break(s) (0,15,30,45,60)': '',
    'Break Qty (1,2,3,4,5)': '',
    'Unpaid (Format: Y or N)': '',
    'Dynamic Staff Pay Rate (Optional)': '',
    'Uniform Requirements (Optional)': '',
    'In-House Shift Information (Optional)': '',
    'Shift Type (Format: Assign Only or Open)': '',
  },
]
